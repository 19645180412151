import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore/lite";
import { db } from "../../firebase.js"; // Ensure this path is correct
import InputMask from "react-input-mask";
import { LanguageContext } from "../../context/LanguageContext";
import lang from "../../components/translations";

const PersonalInfo = ({ nextStep, personalInfo, handleDataChange }) => {
  const [errors, setErrors] = useState({});
  const { appLanguage } = useContext(LanguageContext);
  const [originalData, setOriginalData] = useState(null); // Store the original data

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (!originalData && personalInfo) {
      setOriginalData({ ...personalInfo });
    }
  }, [originalData, personalInfo]);

  useEffect(() => {
    const forms = document.querySelectorAll(".needs-validation");
    Array.from(forms).forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }, []);

  const validate = () => {
    let tempErrors = {};
    let valid = true;

    if (!personalInfo.fullName || personalInfo.fullName.length < 13 || personalInfo.fullName.length > 50) {
      tempErrors.fullName = lang[appLanguage].piValFullname;
      valid = false;
    }

    if (!personalInfo.gender) {
      tempErrors.gender = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!personalInfo.dob || new Date(personalInfo.dob) > new Date()) {
      tempErrors.dob = lang[appLanguage].piValDoB;
      valid = false;
    }

    if (!personalInfo.placeOfBirth || personalInfo.placeOfBirth.length < 3 || personalInfo.placeOfBirth.length > 50) {
      tempErrors.placeOfBirth = lang[appLanguage].piValPoB;
      valid = false;
    }

    if (!personalInfo.maritalStatus) {
      tempErrors.maritalStatus = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (
      personalInfo.maritalStatus !== "Single" &&
      (personalInfo.numberOfChildren === "" || personalInfo.numberOfChildren < 0 || personalInfo.numberOfChildren > 15)
    ) {
      tempErrors.numberOfChildren = lang[appLanguage].piValChildNum;
      valid = false;
    }

    if (!personalInfo.nationality || personalInfo.nationality.length < 3 || personalInfo.nationality.length > 20) {
      tempErrors.nationality = lang[appLanguage].piValNationality;
      valid = false;
    }

    if (!personalInfo.religion || personalInfo.religion.length < 3 || personalInfo.religion.length > 15) {
      tempErrors.religion = lang[appLanguage].piValReligion;
      valid = false;
    }

    if (
      !personalInfo.currentAddress ||
      personalInfo.currentAddress.length < 10 ||
      personalInfo.currentAddress.length > 55
    ) {
      tempErrors.currentAddress = lang[appLanguage].piValAddress;
      valid = false;
    }

    const phoneRegex = /^\(\+\d{3}\) \d{3} \d{3} \d{2} \d{2}$/;
    if (
      personalInfo.phoneNumber === "" ||
      personalInfo.phoneNumber === "(+___) ___ ___ __ __" ||
      !phoneRegex.test(personalInfo.phoneNumber)
    ) {
      tempErrors.phoneNumber = lang[appLanguage].valPhone;
      valid = false;
    }

    if (personalInfo.linkedinURL) {
      const linkedinUrlRegex = /^https:\/\/(www\.)?linkedin\.com\/.*$/i;
      if (!linkedinUrlRegex.test(personalInfo.linkedinURL)) {
        tempErrors.linkedinURL = lang[appLanguage].piValLinkedIn;
        valid = false;
      }
    }

    setErrors(tempErrors);

    return valid;
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    // Call handleDataChange to update form data in the wizard's state
    handleDataChange(
      {
        ...personalInfo, // Spread the current personalInfo data
        [name]: type === "number" ? Number(value) : value, // Update the specific field
      },
      "personalInfo"
    ); // Use "personalInfo" as the key

    // If the phone number is being changed, validate it
    if (name === "phoneNumber") {
      handlePhoneValidation(value);
    }
  };

  const handlePhoneValidation = (value) => {
    // Regex to match a complete phone number
    const phoneRegex = /^\(\+\d{3}\) \d{3} \d{3} \d{2} \d{2}$/;

    // Check if the phone number is valid
    if (!value || value === "" || value === "(+___) ___ ___ __ __" || value.includes("_") || !phoneRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: lang[appLanguage].valPhone,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: undefined,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the personalInfo data passed through props
    if (validate()) {
      const updatedPersonalInfo = {
        ...personalInfo,
        numberOfChildren: personalInfo.maritalStatus === "Single" ? 0 : personalInfo.numberOfChildren,
      };

      const hasChanged = JSON.stringify(originalData) !== JSON.stringify(updatedPersonalInfo);

      console.log(JSON.stringify(originalData));
      console.log(JSON.stringify(updatedPersonalInfo));

      if (!hasChanged) {
        nextStep();
        return;
      }

      try {
        if (personalInfo.id) {
          // Update existing document
          const docRef = doc(db, "personalInfo", personalInfo.id);
          await updateDoc(docRef, updatedPersonalInfo);
        } else {
          // Create a new document
          await addDoc(collection(db, "personalInfo"), updatedPersonalInfo);
        }

        // Move to the next step
        nextStep();
      } catch (e) {
        console.error("Error updating document: ", e);
      }
    }
  };

  return (
    <div className="container">
      <p className="text-muted">
        {lang[appLanguage].piDesc1}
        <br />
        {lang[appLanguage].piDesc2}
        <br />
        {lang[appLanguage].piDesc3}
      </p>
      <hr />
      <div className="mb-4 mx-0 navbar rounded-2 row shadow">
        <div className="col-12 col-md-6">
          <h4>
            <i className="bi bi-person-vcard-fill"></i> {lang[appLanguage].piTitle}
          </h4>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="needs-validation" noValidate>
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="emailInput" className="form-label">
              {lang[appLanguage].piEmail}
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text">@</span>
              <input
                type="email"
                className="form-control"
                id="emailInput"
                name="email"
                value={personalInfo.email}
                onChange={handleChange}
                readOnly
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label htmlFor="fullnameInput" className="form-label">
                {lang[appLanguage].piFullname}
              </label>
              <input
                type="text"
                className={`form-control ${errors.fullName ? "is-invalid" : ""}`}
                id="fullnameInput"
                name="fullName"
                value={personalInfo.fullName}
                onChange={handleChange}
                aria-describedby="fullnameHelp"
                placeholder="ex: John Michael Smith Doe"
                required
                minLength={13}
                maxLength={50}
              />
              <div id="fullnameHelp" className="form-text"></div>
              <div className="invalid-feedback">{errors.fullName}</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="genderInput" className="form-label">
              {lang[appLanguage].piGender}
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <i
                  className={`bi bi-gender-${personalInfo.gender === "Male" ? "male" : personalInfo.gender === "Female" ? "female" : "ambiguous"}`}
                ></i>
              </span>
              <select
                className={`form-select ${errors.gender ? "is-invalid" : ""}`}
                id="genderInput"
                name="gender"
                value={personalInfo.gender}
                onChange={handleChange}
                required
              >
                <option value="" disabled selected>
                  {lang[appLanguage].selectOpt}
                </option>
                <option value="Male">{lang[appLanguage].piGenMale}</option>
                <option value="Female">{lang[appLanguage].piGenFemale}</option>
              </select>
              <div className="invalid-feedback">{errors.gender}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="dobInput" className="form-label">
              {lang[appLanguage].piDoB}
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                <i className="bi bi-cake"></i>
              </span>

              <input
                type="date"
                name="dob"
                className={`form-control ${appLanguage === "en" ? "" : "text-start"} ${errors.dob ? "is-invalid" : ""}`}
                id="dobInput"
                value={personalInfo.dob}
                onChange={handleChange}
                required
                max={today}
              />
              <div className="invalid-feedback">{errors.dob}</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label htmlFor="pobInput" className="form-label">
                {lang[appLanguage].piPoB}
              </label>
              <input
                type="text"
                className={`form-control ${errors.placeOfBirth ? "is-invalid" : ""}`}
                id="pobInput"
                name="placeOfBirth"
                value={personalInfo.placeOfBirth}
                onChange={handleChange}
                required
                placeholder="ex: Baghdad, Iraq"
                minLength={3}
                maxLength={50}
              />
              <div className="invalid-feedback">{errors.placeOfBirth}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label htmlFor="maritalStatusInput" className="form-label">
                {lang[appLanguage].piMStatus}
              </label>
              <select
                className={`form-select ${errors.maritalStatus ? "is-invalid" : ""}`}
                id="maritalStatusInput"
                name="maritalStatus"
                value={personalInfo.maritalStatus}
                onChange={handleChange}
                required
              >
                <option value="" disabled selected>
                  {lang[appLanguage].selectOpt}
                </option>
                <option value="Single">{lang[appLanguage].piMstatSingle}</option>
                <option value="Engaged">{lang[appLanguage].piMstatEngaged}</option>
                <option value="Married">{lang[appLanguage].piMstatMarried}</option>
                <option value="Divorced-Separated">{lang[appLanguage].piMstatDivorced}</option>
              </select>
              <div className="invalid-feedback">{errors.maritalStatus}</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label
                htmlFor="numberOfChildrenInput"
                className="form-label"
                hidden={personalInfo.maritalStatus === "Single" || personalInfo.maritalStatus == null}
              >
                {lang[appLanguage].piChildNum}
              </label>
              <input
                type="number"
                className={`form-control ${appLanguage === "en" ? "" : "text-start"} ${errors.numberOfChildren ? "is-invalid" : ""}`}
                id="numberOfChildrenInput"
                name="numberOfChildren"
                value={personalInfo.maritalStatus === "Single" ? 0 : personalInfo.numberOfChildren}
                onChange={handleChange}
                hidden={personalInfo.maritalStatus === "Single" || personalInfo.maritalStatus == null}
                required={personalInfo.maritalStatus !== "Single"}
                min={0}
                max={15}
              />
              <div className="invalid-feedback">{errors.numberOfChildren}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label htmlFor="nationalityInput" className="form-label">
                {lang[appLanguage].piNationality}
              </label>
              <input
                type="text"
                className={`form-control ${errors.nationality ? "is-invalid" : ""}`}
                id="nationalityInput"
                name="nationality"
                value={personalInfo.nationality}
                onChange={handleChange}
                required
                placeholder="ex: Iraqi"
                minLength={3}
                maxLength={20}
              />
              <div className="invalid-feedback">{errors.nationality}</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label htmlFor="religionInput" className="form-label">
                {lang[appLanguage].piReligion}
              </label>
              <input
                type="text"
                className={`form-control ${errors.religion ? "is-invalid" : ""}`}
                id="religionInput"
                name="religion"
                value={personalInfo.religion}
                onChange={handleChange}
                placeholder="ex: Muslim, Christian, etc..."
                required
                minLength={3}
                maxLength={15}
              />
              <div className="invalid-feedback">{errors.religion}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="mb-3">
              <label htmlFor="currentAddressInput" className="form-label">
                {lang[appLanguage].piAddress}
              </label>
              <input
                type="text"
                className={`form-control ${errors.currentAddress ? "is-invalid" : ""}`}
                id="currentAddressInput"
                name="currentAddress"
                value={personalInfo.currentAddress}
                onChange={handleChange}
                required
                placeholder="Al-Dora, Baghdad, Iraq"
                minLength={10}
                maxLength={55}
              />
              <div className="invalid-feedback">{errors.currentAddress}</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="phoneNumber" className="form-label">
              {lang[appLanguage].piPhone}
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="bi bi-telephone"></i>
              </span>

              <InputMask
                mask="(+999) 999 999 99 99"
                value={personalInfo.phoneNumber}
                onChange={handleChange}
                id="phoneNumber"
                name="phoneNumber"
                dir="ltr"
                className={`form-control ${appLanguage === "en" ? "" : "text-start"} ${errors.phoneNumber ? "is-invalid" : ""}`}
                required
                pattern="\(\+\d{3}\) \d{3} \d{3} \d{2} \d{2}"
              />
              <div className="invalid-feedback">{errors.phoneNumber}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="linkedinURLInput" className="form-label">
              {lang[appLanguage].piLinkedIn}
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="bi bi-linkedin"></i>
              </span>
              <input
                type="text"
                className={`form-control ${errors.linkedinURL ? "is-invalid" : ""}`}
                id="linkedinURLInput"
                name="linkedinURL"
                value={personalInfo.linkedinURL}
                onChange={handleChange}
                placeholder="ex: https://www.linkedin.com/in/jhon-dhoe/"
              />
              <div className="invalid-feedback">{errors.linkedinURL}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            <button type="submit" className="btn btn-success button">
              {lang[appLanguage].btnSaveCont}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

PersonalInfo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  personalInfo: PropTypes.object.isRequired,
  handleDataChange: PropTypes.func.isRequired,
};

export default PersonalInfo;
